import {graphql} from "gatsby";
import * as React from "react";
import {Breadcrumb} from "../components/Breadcrumb";
import {PageHeader} from "../components/PageHeader";
import {Seo} from "../components/Seo";
import {generateRandomColor} from "../util/colors";
import {routes} from "../util/routes";
import {SortPostsByYear} from "../components/sortPostsByYear";

const SingleCategory = ({data, pageContext}) => {
    return (
        <>
            <Seo title={`${pageContext.name} - Category`} />
            <Breadcrumb
                breadcrumbs={[
                    {
                        label: "Blog",
                        url: routes.blog,
                    },
                    {
                        label: "Category",
                    },
                ]}
                activeLabel={pageContext.name}
            />
            <PageHeader
                title="Category"
                periodColor={generateRandomColor(2)}
                description={`Posts categorized: ${pageContext.name}`}
            />
            <SortPostsByYear articles={data.allGraphCmsArticle.nodes} name={pageContext.name} />
        </>
    );
};

export const query = graphql`
  query GetArticleCategoryQuery($id: String) {
    allGraphCmsArticle(
      filter: { category: { id: { eq: $id }, stage: { eq: PUBLISHED } } }
    ) {
      nodes {
        id
        slug
        title
        created
        contentImage {
          image {
            gatsbyImageData(placeholder: BLURRED, quality:70)
            alt
          }
        }
        tags {
          label
        }
      }
    }
  }
`;

export default SingleCategory;
